// eslint-disable-next-line no-redeclare,no-unused-vars
function dataController(dataPart, xhr, param) {
  // switch new dialog
  if (
    xhr.id &&
    param.view &&
    param.view.id == 'new' &&
    data[param.object].new
  ) {
    var view = param.view;
    delete viewStack[view.ident];
    data[param.object][xhr.id] = data[param.object].new;
    delete data[param.object].new;
    elements[param.object][xhr.id] = elements[param.object].new;
    delete elements[param.object].new;
    setData(param.object, xhr.id, 'id', xhr.id, false, param.objectSub);
    param.ident = [param.object, param.objectSub, xhr.id]
      .filter(Boolean)
      .join('_');
    view.id = xhr.id;
    view.ident = param.ident;
    view.attr('ident', param.ident).attr('data-id', xhr.id);
    viewStack[view.ident] = view;
  }

  // update elements
  dataTmp = dataPart;
  $.each(dataPart, function (object, table) {
    if (!data[object]) data[object] = {};
    $.each(table, function (id, row) {
      // handle list data
      if (object == 'lists') {
        if (!id || id == 'sequence') return;
        else if (
          !arrayEqual(data[object][id], row) ||
          !getObjectValue(data, ['lists', 'sequence', id])
        ) {
          data.lists[id] = row;
          if (!data.lists.sequence) data.lists.sequence = {};
          data.lists.sequence[id] = getObjectValue(table, ['sequence', id]);
          if (getObjectValue(elements, ['lists', id + '_els']))
            $.each(elements.lists[id + '_els'], function (i, el) {
              if (el) el.valList(row, true);
            });
        }
        return;
      }

      // handle object data
      if (!id || !row) return;
      if (row.id) id = row.id;
      else if (!isNumber(id) && isNumeric(id)) id = parseInt(id);
      if (!data[object][id]) data[object][id] = {};
      if (
        getObjectValue(param, ['send', 'update']) &&
        getObjectValue(param, ['table', 'ids']) &&
        inArray(id, param.table.ids)
      )
        data[object][id] = {};
      // if (row.id && row.id != id) data[object][row.id] = getData(object, id);
      $.each(row, function (key, value) {
        setData(object, id, key, value, false, null, null, null, param);
      });
      row = getData(object, id);

      // table data via table callback
      if (
        param.send &&
        param.send.table &&
        object == param.object &&
        xhr.ids &&
        inArray(row.id, xhr.ids)
      )
        return;
      else if (param.noTableCheck || parameter[object].noTableCheck) return;
      // add or remove from table, table sort
      else if (getObjectValue(elements, ['tables', object + '_els']))
        $.each(elements.tables[object + '_els'], function (i, table) {
          if (!table) return;
          else if (table.element && !table.element.parents('body')[0]) {
            delete elements.tables[object + '_els'][i];
            return;
          } else if (
            table.search ||
            (table.tableFields &&
              table.tableFields.length > Object.keys(row).length) ||
            (param.top && param.top.ident == table.ident)
          )
            return;
          // else if (globalFilter && globalFilter.values && !checkWhere(row, globalFilter.values, {object: object})) return;

          if (!table.filterJs)
            table.filterJs = convertWhere(table.where, {object: object});
          var match = checkWhere(row, table.filterJs, {
            object: object,
            noConvert: true,
          });
          if (match === null) return;
          var found = false;
          if (table.tbody && table.tbody.find('tr[data-id=' + id + ']')[0])
            found = true;
          else if (table.ids && inArray(id, table.ids)) found = true;
          if (match && !found) table.add(row, param);
          else if (!match && found) table.rem(row);
          else if (row.removeFromTables) table.rem(row);
          // if (match && !inArray(id, table.ids) && (!table.tbody || !table.tbody.find('[data-id=' + id + ']')[0])) table.add(row, param);
          // else if (!match && inArray(id, table.ids) && (!table.tbody || table.tbody.find('[data-id=' + id + ']')[0])) table.rem(row);
          else if (match && table.sorter) table.sorter(row, param.event);
        });
    });
  });
  dataTmp = {};

  // user roll
  // if (user.setupUser && role && dataPart.user && dataPart.user[user.id]) role = user.roll; -> bug after changing role
}
